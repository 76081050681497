import React, { useState, useEffect } from 'react';
import MainPage from './components/MainPage';
import Patients from './components/Patients';
import Graphs from './components/Graphs';
import Statistics from './components/Statistics';
import Report from './components/Report';
import Help from './components/Help';
import About from './components/About';
import Login from './components/login';
import PatientDetail from './components/PatientDetail';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.css';  
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { auth } from './firebase';

const App = () => {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setAuthenticated(true);
      } else {
        setAuthenticated(false); 
      }
    });

    return unsubscribe;
  }, []);

  return (
    <Router>
      <ToastContainer /> {/* Add ToastContainer here */}
      <Routes>
        <Route
          path="/"
          element={<Login/>}
        />
        <Route
          path="/mainpage"
          element={authenticated ? <MainPage /> : <Navigate to="/" />}
        />
        <Route
          path="/zoznampacientov"
          element={authenticated ? <Patients /> : <Navigate to="/" />}
        />
        <Route
          path="/grafy"
          element={authenticated ? <Graphs /> : <Navigate to="/" />}
        />
        <Route
          path="/statistiky"
          element={authenticated ? <Statistics /> : <Navigate to="/" />}
        />
        <Route
          path="/report"
          element={authenticated ? <Report /> : <Navigate to="/" />}
        />
        <Route
          path="/pomoc"
          element={authenticated ? <Help /> : <Navigate to="/" />}
        />
        <Route
          path="/about"
          element={authenticated ? <About /> : <Navigate to="/" />}
        />
        <Route
          path="/patient/:id"
          element={authenticated ? <PatientDetail /> : <Navigate to="/" />}
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
