import React from 'react';
import Sidebar from './sidebar';

const Help = () => {
    return (
        <div className="dashboard">
          <Sidebar className="sidebar"/>
          <div className="content">
            <h2>Ako používať túto aplikáciu</h2>
            <p>Vitajte v našej aplikácii! Tu je niekoľko návodov na jej používanie:</p>
            <h3>Ako vyhľadávať používateľov</h3>
            <p>Na paneli na ľavej strane nájdete vyhľadávací panel. Stačí doňho napísať meno alebo email používateľa a stlačiť Enter.</p>
            <h3>Ako filtrovať výsledky</h3>
            <p>V paneli filtrov môžete vybrať rôzne filtre pre zúženie výsledkov podľa pohlavia, vzdelania, alebo ročníka narodenia.</p>
            <h3>Ako generovať report</h3>
            <p>Kliknite na tlačidlo "Generovať report", zvoľte typ súboru (XLSX alebo XML), a stlačte tlačidlo "Generovať report".</p>
            <h3>Ako zobraziť pomoc</h3>
            <p>Pre zobrazenie tejto nápovedy kliknite na tlačidlo "Pomoc" v hornom paneli.</p>
            <p>Dúfame, že Vám naša aplikácia pomôže! Ak máte akékoľvek ďalšie otázky, neváhajte nás kontaktovať.</p>
        </div>
        </div>
      );
}

export default Help;