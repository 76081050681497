import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { ref, onValue, set } from 'firebase/database';
import Sidebar from './sidebar';
import { Link, useParams } from 'react-router-dom';
import { auth } from '../firebase';
import diagnosesData from '../sources/diagnozy.json';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal'; // Import Modal component from Material-UI
import '../styling/patients.css';
import Checkbox from '@mui/material/Checkbox';
import { toast } from 'react-toastify';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

function Patients() {
    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [genderFilter, setGenderFilter] = useState('');
    const [educationFilter, setEducationFilter] = useState('');
    const [birthYearStartFilter, setBirthYearStartFilter] = useState('1930');
    const [birthYearEndFilter, setBirthYearEndFilter] = useState('');
    const [currentUserEmail, setCurrentUserEmail] = useState(null);
    const [diagnoses, setDiagnoses] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null); // State to store the selected user
    const [openModal, setOpenModal] = useState(false); // State to control modal visibility

    const [selectedDiagnoses, setSelectedDiagnoses] = useState([]);

    const handleToggleDiagnosis = (diagnosisId) => {
        if (selectedDiagnoses.includes(diagnosisId)) {
            setSelectedDiagnoses(selectedDiagnoses.filter(id => id !== diagnosisId));
        } else {
            setSelectedDiagnoses([...selectedDiagnoses, diagnosisId]);
        }
    };

    const handleSaveDiagnoses = () => {
        const userRef = ref(db, `users/registration/${selectedUser.id}/diagnoses`);
    
        let diagnosesToSave;
        console.log(selectedDiagnoses + selectedDiagnoses.length);
        if (Array.isArray(selectedDiagnoses) && selectedDiagnoses.length > 0) {
            diagnosesToSave = selectedDiagnoses.filter(diagnosisId => diagnosisId !== -1);
        } else {
            diagnosesToSave = [-1];
        }
    
        set(userRef, diagnosesToSave)
            .then(() => {
                toast.success(`Diagnózy pre pacienta boli úspešne zapísané!`);
                handleCloseModal();
            })
            .catch((error) => {
                toast.error("Problém s ukladaním diagnóz: " + error.message);
            });
    };
    
    
    
    

    
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                setCurrentUserEmail(user.email);
            } else {
                setCurrentUserEmail(null);
            }
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        setDiagnoses(diagnosesData);
        const usersRef = ref(db, 'users/registration/');
        onValue(usersRef, (snapshot) => {
            const data = snapshot.val();
            const loadedUsers = [];

            if (data) {
                for (const userId in data) {
                    const user = data[userId];

                    loadedUsers.push({
                        id: userId,
                        iduser: user.id || "Not specified",
                        doctor_email: user.doctor_email || "Not specified",
                        username: user.username || "Not specified",
                        email: user.email,
                        gender: user.gender || "Not specified",
                        birthYear: user.birthYear || "Not specified",
                        education: user.education || "Not specified",
                        diagnoses: user.diagnoses || "Not specified"
                    });
                }
                console.log(loadedUsers);
            }

            setUsers(loadedUsers);
        });

        return () => onValue(usersRef, () => {});
    }, []);

    const getDiagnosesText = (userDiagnoses) => {
        if (!userDiagnoses || (userDiagnoses.length === 1 && userDiagnoses[0] === -1)) {
            return "Žiadne"; 
        }
        if (!userDiagnoses || userDiagnoses === "Žiadne") {
            return "Žiadne";
        }
    
        if (!Array.isArray(userDiagnoses)) {
            return ''; // Return an empty string if userDiagnoses is not an array
        }
    
        const filteredDiagnoses = userDiagnoses.filter(diagnosis => typeof diagnosis === 'number');
    
        if (filteredDiagnoses.length === 0) {
            return "Žiadne";
        }
    
        const diagnosesTextArray = filteredDiagnoses.map(diagnosisNumber => {
            const diagnosis = diagnoses.find(d => d.id === diagnosisNumber);
            return diagnosis ? `${diagnosis.text}` : '';
        });
    
        return diagnosesTextArray.join(', ');
    };
    
    

    const filteredUsers = users.filter(user =>
        (user.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user.email.toLowerCase().includes(searchQuery.toLowerCase())) &&
        (genderFilter === '' || user.gender === genderFilter) &&
        (educationFilter === '' || user.education === educationFilter) &&
        (birthYearStartFilter === '' || parseInt(user.birthYear) >= birthYearStartFilter) &&
        (birthYearEndFilter === '' || parseInt(user.birthYear) <= birthYearEndFilter)
    );

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleGenderFilterChange = (event) => {
        setGenderFilter(event.target.value);
    };

    const handleEducationFilterChange = (event) => {
        setEducationFilter(event.target.value);
    };

    const handleBirthYearStartFilterChange = (event) => {
        setBirthYearStartFilter(parseInt(event.target.value));
    };

    const handleBirthYearEndFilterChange = (event) => {
        setBirthYearEndFilter(parseInt(event.target.value));
    };

    const handleOpenModal = (user) => {
        setSelectedUser(user); // Store the selected user
        setOpenModal(true);
        setSelectedDiagnoses(user.diagnoses || []);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const trueCount = filteredUsers.reduce((count, user) => {
        return count + (currentUserEmail === user.doctor_email ? 1 : 0);
    }, 0);

    return (
        <div className="dashboard">
            <Sidebar className="sidebar" />
            <div className="content">
                <h1 className="header">Zoznam pacientov</h1>
                <div>
                    <input
                        type="text"
                        placeholder="Hľadať vo výsledkoch..."
                        value={searchQuery}
                        onChange={handleSearchInputChange}
                    />
                </div>
                <div className="Filtercontainer">
                    <label htmlFor="genderFilter">Pohlavie:</label>
                    <select id="genderFilter" value={genderFilter} onChange={handleGenderFilterChange}>
                        <option value="">Obe</option>
                        <option value="Muž">Muž</option>
                        <option value="Žena">Žena</option>
                    </select>
                </div>
                <div className="Filtercontainer">
                    <label htmlFor="educationFilter">Vzdelanie:</label>
                    <select id="educationFilter" value={educationFilter} onChange={handleEducationFilterChange}>
                        <option value="">Všetko</option>
                        <option value="Stredoškolské">Stredoškolské</option>
                        <option value="Vysokoškolské">Vysokoškolské</option>
                        <option value="Základné">Základné</option>
                    </select>
                </div>
                <div className="Filtercontainer">
                    <label htmlFor="birthYearStartFilter">Rok narodenia (od):</label>
                    <input
                        type="number"
                        id="birthYearStartFilter"
                        value={birthYearStartFilter}
                        onChange={handleBirthYearStartFilterChange}
                    />
                </div>
                <div className="Filtercontainer">
                    <label htmlFor="birthYearEndFilter">Rok narodenia (do):</label>
                    <input
                        type="number"
                        id="birthYearEndFilter"
                        value={birthYearEndFilter}
                        onChange={handleBirthYearEndFilterChange}
                    />
                </div>
                <p className='patienttitle'>Počet registrovaných používateľov (ktorí vyhovujú filtrom): <span className="red-text">{trueCount}</span></p>
                <ul>
                    {filteredUsers.length > 0 ? (
                        filteredUsers.map((user) => (
                            currentUserEmail === user.doctor_email && (
                                <li key={user.id}>
                                    <p>Meno pacienta: {user.username}, {user.email} - {user.gender} - {user.birthYear} - vzdelanie: {user.education}, diagnózy: {getDiagnosesText(user.diagnoses)}</p>
                                    <Link to={`/patient/${user.id}`} className="patient">
                                    <Button size="medium" variant="contained" color="primary" className="button">Otvoriť pacientové dotazníky</Button>
                                    </Link>
                                    <span style={{ marginRight: '10px' }} /> {/* Add space between buttons */}
                                    <Button size="medium" variant="contained" sx={{
                                        backgroundColor: '#05C318', 
                                        color: 'white', 
                                        '&:hover': { 
                                        backgroundColor: '#2ECC71', 
                                        },
                                    }} className="button" onClick={() => handleOpenModal(user)}>Zmeniť pacientové diagnózy</Button>
                                    <div className="chartSeparator"></div>
                                </li>
                            )
                        ))
                    ) : (
                        <li>Nenašli sa žiadni pacienti.</li>
                    )}
                </ul>
            </div>
            {/* Modal */}
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                    Zmena diagnóz - pacient : {selectedUser?.username}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Doterajšie diagnózy: {getDiagnosesText(selectedUser?.diagnoses)}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Dostupné diagnózy na výber:
                        <ul style={{ listStyleType: 'none', padding: 0 }} >
                            {diagnoses.map((diagnosis, index) => (
                                <li style={{ cursor: 'pointer' }} key={index} onClick={() => handleToggleDiagnosis(diagnosis.id)}>
                                    <Checkbox
                                        checked={selectedDiagnoses.includes(diagnosis.id)}
                                        onChange={() => handleToggleDiagnosis(diagnosis.id)}
                                    />
                                    {diagnosis.text}
                                </li>
                            ))}
                        </ul>
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <Button size="big" variant="contained" onClick={handleSaveDiagnoses}>Uložiť</Button>
                    <span style={{ marginRight: '10px' }} />
                    <Button size="big" variant="contained" color="error" onClick={handleCloseModal}>Zrušiť</Button>
                    </Typography>
                 </Box>               
            </Modal>
        </div>
    );
}

export default Patients;
