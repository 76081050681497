import React, { useEffect, useState } from 'react';
import { BiHome,  BiSolidReport, BiStats, BiTask, BiHelpCircle } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import '../styling/sidebar.css';
import img from '../images/breathlogo.png';
import Button from '@mui/material/Button';
import { useNavigate  } from 'react-router-dom'
import { auth } from '../firebase';
import useIsAdmin from './isAdmin'; 
import { db } from '../firebase';

const Sidebar = () => {
    const navigate = useNavigate();
    const { isAdmin, checkAdmin } = useIsAdmin(); 

    useEffect(() => {
        const currentUser = auth.currentUser;
        if (currentUser) {
            checkAdmin(currentUser.email, db); 
        }
    }, [checkAdmin]);

    const handleLogOut = async () => {
        auth.signOut().then(() => {
            navigate('/');
          }).catch((error) => {
            console.error('Logout error:', error);
          });
      };

  return (
    <div className="menu">
        <Link to='/mainpage' className="logo"> 
            <img className="image "src={img} alt="Logo" />
        </Link>

        <div className="menu--item">
        {isAdmin ? (<div className="admin-text">Admin verzia zapnutá</div>) : null}
            <Link to='/zoznampacientov' className="item">
                <BiHome className="icon"/>
                <h3>Zoznam pacientov</h3>
            </Link>
            <Link to='/grafy' className="item">
                <BiTask className="icon"/>
                <h3>Grafy</h3>
            </Link>
            <Link to='/statistiky' className="item">
                <BiStats className="icon"/>
                <h3>Štatistiky</h3>
            </Link>
            {isAdmin ? (
                <Link to='/report' className="item">
                    <BiSolidReport className="icon"/>
                    <h3>Report</h3>
                </Link>
            ) : null}
            <Link to='/pomoc' className="item">
                <BiHelpCircle className="icon"/>
                <h3>Pomoc</h3>
            </Link>
            
            <Button onClick={handleLogOut} variant="outlined" color="error" className="button" >Odhlásiť sa</Button>
        </div>
    </div>
  );
}

export default Sidebar;
