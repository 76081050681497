import React, { useState, useEffect } from 'react';
import Sidebar from './sidebar';
import { db } from '../firebase';
import { ref, onValue } from 'firebase/database';
import '../styling/graphs.css';
import { auth } from '../firebase';

function Statistics() {
    const [users, setUsers] = useState([]);
    const [numberOfMen, setNumberOfMen] = useState(0);
    const [numberOfWomen, setNumberOfWomen] = useState(0);
    const [zakladneVzdelanie, setZakladneVzdelanie] = useState(0);
    const [stredneVzdelanie, setStredneVzdelanie] = useState(0);
    const [vysokaSkola, setVysokaSkola] = useState(0);
    const [averageAge, setAverageAge] = useState(0);
    const [medianAge, setMedianAge] = useState(0);
    const [modeAge, setModeAge] = useState(0);
    const [ageRange, setAgeRange] = useState(0);
    const [percentile25, setPercentile25] = useState(0);
    const [percentile75, setPercentile75] = useState(0);
    const [ageVariance, setAgeVariance] = useState(0);
    const [ageStandardDeviation, setAgeStandardDeviation] = useState(0);
    const [maleAges, setMaleAges] = useState([]);
    const [femaleAges, setFemaleAges] = useState([]);
    const [youngUsers, setYoungUsers] = useState([]);
    const [middleAgedUsers, setMiddleAgedUsers] = useState([]);
    const [oldUsers, setOldUsers] = useState([]);


    const [currentUserEmail, setCurrentUserEmail] = useState(null);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                setCurrentUserEmail(user.email);
            } else {
                setCurrentUserEmail(null);
            }
        });

        return () => unsubscribe();
    }, []);


    useEffect(() => {
        const usersRef = ref(db, 'users/registration/');
        onValue(usersRef, (snapshot) => {
            const data = snapshot.val();
            const loadedUsers = [];
            let menCount = 0;
            let womenCount = 0;
            let zakladneVzdelanie = 0;
            let stredneVzdelanie = 0;
            let vysokaSkola = 0;
            let ages = [];

            if (data) {
                for (const userId in data) {
                    const user = data[userId];
                    if(user.doctor_email === currentUserEmail){
                        if (user.gender === "Muž") {
                            menCount++;
                        } else {
                            womenCount++;
                        }
                        if (user.education === "Základné") {
                            zakladneVzdelanie++;
                        } else if (user.education === "Stredoškolské") {
                            stredneVzdelanie++;
                        } else {
                            vysokaSkola++;
                        }

                        if (user.birthYear) {
                            ages.push(new Date().getFullYear() - user.birthYear);
                        }

                        loadedUsers.push({
                            username: user.username,
                            doctor_email: user.doctor_email || "Not specified",
                            email: user.email,
                            gender: user?.gender || "Not specified", // Optional chaining added here
                            birthYear: user.birthYear || "Not specified",
                            education: user.education || "Not specified",
                            diagnoses: user.diagnoses || "Not specified"
                        });
                    }
                }
            }

            setNumberOfMen(menCount);
            setNumberOfWomen(womenCount);
            setUsers(loadedUsers);

            setZakladneVzdelanie(zakladneVzdelanie);
            setStredneVzdelanie(stredneVzdelanie);
            setVysokaSkola(vysokaSkola);

            const totalAge = ages.reduce((acc, curr) => acc + curr, 0);
            const avgAge = totalAge / ages.length;
            setAverageAge(avgAge);

            const sortedAges = ages.sort((a, b) => a - b);
            const middleIndex = Math.floor(sortedAges.length / 2);
            const medianAge = sortedAges.length % 2 === 0 ? (sortedAges[middleIndex - 1] + sortedAges[middleIndex]) / 2 : sortedAges[middleIndex];
            setMedianAge(medianAge);

            // Calculate mode
            const ageCount = {};
            ages.forEach(age => {
                ageCount[age] = (ageCount[age] || 0) + 1;
            });
            let maxCount = 0;
            let mode = null;
            for (const age in ageCount) {
                if (ageCount[age] > maxCount) {
                    maxCount = ageCount[age];
                    mode = age;
                }
            }
            setModeAge(mode);

            // Calculate age range
            const oldest = Math.max(...ages);
            const youngest = Math.min(...ages);
            const range = oldest - youngest;
            setAgeRange(range);

            // Calculate percentiles
            const percentileIndex25 = Math.floor(sortedAges.length * 0.25);
            const percentileIndex75 = Math.floor(sortedAges.length * 0.75);
            const percentile25 = sortedAges[percentileIndex25];
            const percentile75 = sortedAges[percentileIndex75];
            setPercentile25(percentile25);
            setPercentile75(percentile75);

            // Calculate age variance
            const ageMean = totalAge / ages.length;
            const variance = ages.reduce((acc, curr) => acc + Math.pow(curr - ageMean, 2), 0) / ages.length;
            setAgeVariance(variance);

            // Calculate age standard deviation
            const standardDeviation = Math.sqrt(variance);
            setAgeStandardDeviation(standardDeviation);

            // Filter ages by gender
            const maleAgesFiltered = ages.filter((age, index) => users[index]?.gender === "Muž"); // Optional chaining added here
            const femaleAgesFiltered = ages.filter((age, index) => users[index]?.gender === "Žena"); // Optional chaining added here
            setMaleAges(maleAgesFiltered);
            setFemaleAges(femaleAgesFiltered);

            // Segment users into age groups
            const youngUsersFiltered = users.filter((user, index) => ages[index] <= 25);
            const middleAgedUsersFiltered = users.filter((user, index) => ages[index] > 25 && ages[index] <= 50);
            const oldUsersFiltered = users.filter((user, index) => ages[index] > 50);
            setYoungUsers(youngUsersFiltered);
            setMiddleAgedUsers(middleAgedUsersFiltered);
            setOldUsers(oldUsersFiltered);
        });

        return () => onValue(usersRef, () => {});
    }, [numberOfMen, numberOfWomen, users, currentUserEmail]);

    return (
        <div className="dashboard">
            <Sidebar className="sidebar" />
            <div className="content" >
                <h1 className="header">Štatistická analýza dát</h1>
                <p>
                    <strong>Štatistiky veku:</strong><br />
                    Priemer veku: {averageAge.toFixed(2)} rokov<br />
                    Medián veku: {medianAge} rokov<br />
                    Modus veku: {modeAge} rokov<br />
                    Rozsah veku: {ageRange} rokov<br />
                    25. percentil: {percentile25} rokov (25% ľudí v dátovej sade je mladších ako {percentile25} rokov)<br />
                    75. percentil: {percentile75} rokov (75% ľudí v dátovej sade je mladších ako {percentile75} rokov)<br />
                    Variabilita veku (rozptyl): {ageVariance.toFixed(2)}<br />
                    Štandardná odchýlka veku: {ageStandardDeviation.toFixed(2)}<br />
                </p>
                <p>
                    <strong>Distribúcia veku podľa pohlavia:</strong><br />
                    Muži: {maleAges.length} (Priemerný vek: {maleAges.length > 0 ? (maleAges.reduce((a, b) => a + b, 0) / maleAges.length).toFixed(2) : "N/A"} rokov)<br />
                    Ženy: {femaleAges.length} (Priemerný vek: {femaleAges.length > 0 ? (femaleAges.reduce((a, b) => a + b, 0) / femaleAges.length).toFixed(2) : "N/A"} rokov)
                </p>
                <p>
                    <strong>Vekové segmentácie:</strong><br />
                    Mladí: {youngUsers.length}<br />
                    Stredne vekoví: {middleAgedUsers.length}<br />
                    Starí: {oldUsers.length}
                </p>
            </div>
        </div>
    );
}

export default Statistics;
