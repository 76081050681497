import React from 'react';
import Sidebar from './sidebar';
import '../styling/mainpage.css';

const MainPage = () => {
    return (
        <div className="dashboard">
          <Sidebar className="sidebar"/>
          <div className="content">
                <h1 >Vitajte</h1>
                <p>Na tejto stránke sa nachádzajú rôzne informácie o pacientoch, štatistikách a reportoch.</p>
                <p>Pre zobrazenie všetkých pacientov kliknite na záložku Zoznam pacientov, pre zobrazenie štatistík kliknite na bočnom paneli na sekciu Štatistika a pre zobrazenie reportov kliknite na Report.</p>
                <p>Grafické zobrazenie je dostupné v záložke Grafy.</p>
          </div>
        </div>
      );
}

export default MainPage;