import { useState } from 'react';
import { db } from '../firebase';
import { ref, onValue, set } from 'firebase/database';

const useIsAdmin = () => {
  const [isAdmin, setIsAdmin] = useState(false);

  const checkAdmin = (email, db) => {
    const adminRef = ref(db, 'admins/');
    onValue(adminRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        for (const userId in data) {
          const user = data[userId];
          if (user.email === email) {
            setIsAdmin(true);
            console.log("isAdmin: trueee");
            return;
          }
        }
      }
    });
  };

  return { isAdmin, checkAdmin };
};

export default useIsAdmin;
