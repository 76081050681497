import { Link, useParams } from 'react-router-dom';
import Sidebar from './sidebar';
import React, { useState, useEffect, useRef } from 'react';
import { db } from '../firebase';
import { ref, onValue } from 'firebase/database';
import '../styling/patientdetail.css';
import { Button, Checkbox } from '@mui/material';
import L from 'leaflet';
import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';
import { purple } from '@mui/material/colors';
import { toast } from 'react-toastify';
import Modal from '@mui/material/Modal'; 
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const PatientComponent = () => {
    const { id } = useParams();
    const [users, setUsers] = useState([]);
    const [surveys, setSurveys] = useState([]);
    const [cities, setCities] = useState({});
    const [loading, setLoading] = useState(true);
    const [clickedCity, setClickedCity] = useState(null);
    const [nameForSearch, setNameForSearch] = useState('');
    const [surveyToShowResults, setSurveyToShowResults] = useState(null);
    const questions = require('../sources/dotaznik.json').otazky;
    const [loadedCity, setLoadedCity] = useState(null);
    const [cityIDUSER, setCityIDUSER] = useState(null);
    const mapRef = useRef(null);
    const mapContainerRef = useRef(null);
    const [lastdays, setlastdays] = useState(2);
    const [openModal, setOpenModal] = useState(false);
    const [selectedSurveys, setSelectedSurveys] = useState([]);

    const [selectedLocations, setSelectedLocations] = useState([]);

    const fetchCityName = async (latitude, longitude) => {
        try {
            const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&zoom=10&addressdetails=1`);
            const data = await response.json();
            if (data && data.address) {
                const cityParts = [];
                if (data.address.neighbourhood) {
                    cityParts.push(data.address.neighbourhood);
                } else if (data.address.suburb) {
                    cityParts.push(data.address.suburb);
                } else {
                    cityParts.push(data.address.city || data.address.town || data.address.village || data.address.county);
                }
                cityParts.push(
                    data.address.county,
                    data.address.state,
                    data.address.country
                );
                const cityName = cityParts.filter(Boolean).join(', ');
                console.log('City name:', cityName);
                return cityName;
            } else {
                console.error('Nominatim API request failed');
                return null;
            }
        } catch (error) {
            console.error('Error fetching city name:', error);
            return null;
        }
    };

    useEffect(() => {
        const usersRef = ref(db, `users/registration/`);
        onValue(usersRef, (snapshot) => {
            const data = snapshot.val();
            const loadedUsers = [];
            if (data && Object.keys(data).length > 0) {
                const user = data[id];
                setNameForSearch(user.id);
                loadedUsers.push({
                    id: id,
                    username: user.username || "Not specified",
                    email: user.email || "Not specified",
                    gender: user.gender || "Not specified",
                    birthYear: user.birthYear || "Not specified",
                    education: user.education || "Not specified",
                    diagnoses: user.diagnoses || "Not specified",

                });
            }
            setUsers(loadedUsers);
        });

        const surveysRef = ref(db, `users/survey/`);
        onValue(surveysRef, async (snapshot) => {
            const data = snapshot.val();
            const loadedSurveys = [];
            if (data) {
                for (const surveyId in data) {
                    const survey = data[surveyId];
                    loadedSurveys.push({
                        id: surveyId,
                        iduser: survey.id,
                        date: survey.date,
                        lat: survey.lat,
                        long: survey.long,
                        result: survey.result,
                        lastdays: survey.lastdays,
                    });
                    setlastdays(survey.lastdays);
                }
            }
            setSurveys(loadedSurveys);
            setLoading(false);
        });

        return () => {
            onValue(usersRef, () => {});
            onValue(surveysRef, () => {});
        };
    }, [id]);

    const toggleResults = (surveyId) => {
        setSurveyToShowResults(surveyToShowResults === surveyId ? null : surveyId);
    };

    useEffect(() => {
        if (cityIDUSER && mapContainerRef.current) {
            const survey = surveys.find(survey => survey.id === cityIDUSER);
            if (survey) {
                renderMap(survey.lat, survey.long);
            }
        }
    }, [cityIDUSER, surveys]);

    const renderMap = (lat, long) => {
        if (mapRef.current) {
            mapRef.current.remove();
        }
        mapRef.current = L.map(mapContainerRef.current).setView([lat, long], 13);
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(mapRef.current);
        const marker = L.marker([lat, long]).addTo(mapRef.current);
        marker.bindPopup("Miesto vyplnenia dotazníka").openPopup();
    };

    // Array of colors for the radar chart
    const colors = [
        'rgba(255, 99, 132, 0.2)', // Red
        'rgba(54, 162, 235, 0.2)', // Blue
        'rgba(75, 192, 192, 0.2)', // Green
        'rgba(153, 102, 255, 0.2)', // Purple
        'rgba(255, 159, 64, 0.2)', // Orange
        'rgba(255, 206, 86, 0.2)', // Yellow
        'rgba(201, 203, 207, 0.2)'  // Grey
    ];

    // Array of border colors for the radar chart
    const borderColors = [
        'rgba(255, 99, 132, 1)', // Red
        'rgba(54, 162, 235, 1)', // Blue
        'rgba(75, 192, 192, 1)', // Green
        'rgba(153, 102, 255, 1)', // Purple
        'rgba(255, 159, 64, 1)', // Orange
        'rgba(255, 206, 86, 1)', // Yellow
        'rgba(201, 203, 207, 1)'  // Grey
    ];

    const getRadarData = (surveys) => {
        if (surveys.length === 0) return { labels: [], datasets: [] };

        const labels = questions.map(q => q.text);
        const datasets = surveys.map((survey, index) => {
            const data = questions.map((q, qIndex) => {
                const rawValue = (survey.result && survey.result[qIndex] !== undefined && survey.result[qIndex] !== -1) ? survey.result[qIndex] : 0;
                return (rawValue / 10) * 10;
            });

            return {
                label:`Dotazník z dňa ${formattedDate(survey)}, ${formattedTime(survey)}`,
                data: data,
                backgroundColor: colors[index % colors.length],  // Assign color based on index
                borderColor: borderColors[index % borderColors.length],  // Assign border color based on index
                borderWidth: 1,
            };
        });

        return {
            labels: labels,
            datasets: datasets,
        };
    };

    const chartOptions = {
        maintainAspectRatio: false,
        scale: {
            suggestedMin: 0,
            suggestedMax: 10,
        },
    };

    const formattedDate = (survey) => {
        return new Date(survey.date)
          .toLocaleDateString('sk-SK', { day: '2-digit', month: '2-digit', year: 'numeric' })
          .replace(/\s+/g, '');
    };

    const formattedTime = (survey) => {
        return new Date(survey.date)
          .toLocaleTimeString('sk-SK', { 
              hour: '2-digit', 
              minute: '2-digit', 
              second: '2-digit' 
          });
    };

    const checkPicked = () => {
        if (selectedSurveys.length < 2) {
            toast.error(`Vyberte prosím aspoň 2 dotazníky na porovnanie.`);
        } else {
            handleOpenModal();
        }
    }

    const handleOpenModal = () => {
        const locationsPromises = selectedSurveys.map(survey => 
            fetchCityName(survey.lat, survey.long)
        );

        Promise.all(locationsPromises).then(locations => {
            setSelectedLocations(locations);
            setOpenModal(true);
        });
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const handleCheckboxChange = (survey, event) => {
        if (event.target.checked) {
            setSelectedSurveys(prev => [...prev, survey]);
        } else {
            setSelectedSurveys(prev => prev.filter(s => s.id !== survey.id));
        }
    };

    return (
        <div className={"dashboard"}>
            <Sidebar className="sidebar" />
            <div className={"content"}>
                <div>{loading && 'Údaje sa načitávaju, nepotrvá to dlho...'}</div>
                {!loading && users.map(user => (
                    <div key={user.id}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <h3>Dotazníky užívateľa {user.username} s emailovou adresou: {user.email}</h3>
                            <Button style={{ marginLeft: '100px' }} variant="outlined" color="secondary" onClick={checkPicked} >
                                Porovnať vybrané dotazníky
                            </Button>
                        </div>

                        <ul className="survey-list">
                            {surveys
                                .filter(survey => survey.iduser === nameForSearch)
                                .sort((a, b) => new Date(b.date) - new Date(a.date))
                                .map(survey => (
                                    <li key={survey.id}>
                                        Dátum: {formattedDate(survey)}, čas: {formattedTime(survey)}
                                        <Button style={{ marginLeft: '40px' }} variant="contained" color="success" onClick={() => toggleResults(survey.id)}>
                                            {surveyToShowResults === survey.id ? 'Skryť odpovede' : 'Ukázať výsledky'}
                                        </Button>
                                        <Button style={{ marginLeft: '40px' }} variant="contained" onClick={() => {
                                            fetchCityName(survey.lat, survey.long).then((cityName) => {
                                                setLoadedCity(cityName);
                                            });
                                            setCityIDUSER(survey.id);
                                            if (cityIDUSER === survey.id) {
                                                setCityIDUSER(null);
                                            }
                                        }}>
                                            {cityIDUSER === survey.id ? 'Skryť geolokáciu dotazníka' : 'Ukázať geolokáciu dotazníka'}
                                        </Button>
                                        <Checkbox
                                            sx={{
                                            color: purple[800],
                                            '&.Mui-checked': {
                                                color: purple[600],
                                            }, marginLeft:"10px"
                                            }}
                                            onChange={(event) => handleCheckboxChange(survey, event)}
                                        />
                                        {surveyToShowResults === survey.id && (
                                            <>
                                                <div>
                                                    <p style={{ color: survey.lastdays === "-1" ? 'red' : survey.lastdays === "1" ? 'green' : 'orange' }}>Príznaky sa za posledných 7 dní: {survey.lastdays === "-1" ? "Zhoršili" : survey.lastdays === "0" ? "Nezmenili" : survey.lastdays === "1" ? "Zlepšili" : "Neznámy stav"}</p>
                                                </div>
                                                <ul className="result-list">
                                                    {questions.map((question, index) => (
                                                        <li key={question.id}>
                                                            <span className="question">{question.text}:</span>
                                                            <span className="answer">
                                                                {(survey.result && survey.result[index] !== undefined && survey.result[index] !== -1) ? survey.result[index] : "Netýka sa ma to"}
                                                            </span>
                                                        </li>
                                                    ))}
                                                </ul>
                                                <div style={{ height: '400px' }}>
                                                    <Radar data={getRadarData([survey])} options={chartOptions} />
                                                </div>
                                            </>
                                        )}
                                        {cityIDUSER === survey.id && (
                                            <>
                                                <p>Zemepisná šírka: {survey.long}</p>
                                                <p>Zemepisná dĺžka: {survey.lat}</p>
                                                <span>Odhadované mesto alebo miesto podľa súradnic: {loadedCity}</span>
                                                <div ref={mapContainerRef} id="map" style={{ width: '55%', height: '300px' }}></div>
                                            </>
                                        )}
                                    </li>
                                ))}
                            {surveys.filter(survey => survey.iduser === nameForSearch).length === 0 && (
                                <p>Žiadne dotazníky viazane k tomuto účtu</p>
                            )}
                        </ul>
                        <Modal
                            open={openModal}
                            onClose={handleCloseModal}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <Typography id="modal-modal-title" variant="h4" component="h2" style={{ paddingBottom: "20px"}}>
                                    Porovnanie vybraných dotazníkov
                                </Typography>
                                <Typography color={"red"} variant="h6">
                                    Odhadované lokácie dotazníkov: 
                                </Typography >
                                {selectedLocations.map((location, index) => (
                                    <Typography key={index} id="modal-modal-description" sx={{ mt: 1 }}>
                                        {`Dotazník ${index + 1}: ${location}`}
                                    </Typography>
                                ))}
                                <div style={{ height: '400px', paddingTop: "20px"}}>
                                    {selectedSurveys.length > 1 ? (
                                        <Radar data={getRadarData(selectedSurveys)} options={chartOptions} />
                                    ) : (
                                        <p>Pre porovnanie je potrebné vybrať aspoň 2 dotazníky.</p>
                                    )}
                                </div>
                            </Box>
                        </Modal>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PatientComponent;
