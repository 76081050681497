import React, { useState, useEffect, useCallback } from 'react';
import { auth } from '../firebase';
import { Tooltip } from 'react-tooltip';
import '../styling/login.css'; 
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import img from '../images/breathlogo.png';
import { db } from '../firebase';
import { ref, onValue } from 'firebase/database';
import useIsAdmin from './isAdmin';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const { isAdmin, checkAdmin } = useIsAdmin();

  const handleLogin = useCallback(async () => {
    try {
      await signInWithEmailAndPassword(auth, username, password);
      // If login successful, navigate to main page
      checkAdmin(username, db);
      navigate('/mainpage');
    } catch (error) {
      // If login fails, set error message
      setLoginError('Prihlasovací email alebo heslo nesprávne.');
      console.error('Error signing in:', error);
    }
  }, [navigate, username, password, checkAdmin]);

  const handleKeyPress = useCallback((e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission
      handleLogin();
    }
  }, [handleLogin]);

  useEffect(() => {
    document.addEventListener('keypress', handleKeyPress);
    return () => {
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, [handleKeyPress]);
  
  return (
    <div className="centering">
      <div className="login-container">
        <img className="image" src={img} alt="Logo" />
        <h2 className="login-title">Prihlásenie pre lekárov</h2>
        <form>
          <label htmlFor="username" className="login-label">
            Prihlasovací email:
            <Tooltip place="top" effect="solid" arrowColor="black">
              <span>Zadajte Vaš email použitý pri registrácií</span>
            </Tooltip>
          </label>
          <input
            type="text"
            id="username"
            className="login-input"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />

          <label htmlFor="password" className="login-label">
            Heslo:
            <Tooltip place="top" effect="solid" arrowColor="black">
              <span>Zadajte Vaše heslo použité pri registrácií</span>
            </Tooltip>
          </label>
          <input
            type="password"
            id="password"
            className="login-input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

          {/* Display error message if loginError is not empty */}
          {loginError && <p className="login-error">{loginError}</p>}

          <button type="button" onClick={handleLogin} className="login-button">
            Prihlásiť sa
          </button>

          <Tooltip effect="solid" />
        </form>
      </div>
    </div>
  );
}

export default Login;
