import React from 'react';
import Sidebar from './sidebar';

const About = () => {
    return (
        <div className="dashboard">
          <Sidebar className="sidebar"/>
          <div className="content">
          </div>
        </div>
      );
}

export default About;