import React, { useState, useEffect } from 'react';
import Sidebar from './sidebar';
import { db } from '../firebase';
import { ref, onValue } from 'firebase/database';
import { PieChart, ScatterChart } from '@mui/x-charts';
import '../styling/graphs.css';
import { auth } from '../firebase';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts'; // Import components from 'recharts'

function Graphs() {
    const [users, setUsers] = useState([]);
    const [numberOfMen, setNumberOfMen] = useState(0);
    const [numberOfWomen, setNumberOfWomen] = useState(0);
  
    const [zakladneVzdelanie, setZakladneVzdelanie] = useState(0);
    const [stredneVzdelanie, setStredneVzdelanie] = useState(0);
    const [vysokaSkola, setVysokaSkola] = useState(0);
  
    const [currentUserEmail, setCurrentUserEmail] = useState(null);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            if (user) {
                setCurrentUserEmail(user.email);
            } else {
                setCurrentUserEmail(null);
            }
        });
    
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const usersRef = ref(db, 'users/registration/');
        onValue(usersRef, (snapshot) => {
            const data = snapshot.val();
            const loadedUsers = [];
            let menCount = 0; // Initialize counts
            let womenCount = 0;
            let zakladneVzdelanieCount = 0;
            let stredneVzdelanieCount = 0;
            let vysokaSkolaCount = 0;
        
            if (data) {
                for (const userId in data) {
                    const user = data[userId];
                    if(user.doctor_email === currentUserEmail){
                        if (user.gender === "Muž") {
                            menCount++; // Increment counts
                        } else {
                            womenCount++;
                        }
                        if (user.education === "Základné") {
                            zakladneVzdelanieCount++;
                        } else if (user.education === "Stredoškolské") {
                            stredneVzdelanieCount++;
                        } else {
                            vysokaSkolaCount++;
                        }

                        // Calculate age
                        const birthYear = user.birthYear;
                        const currentYear = new Date().getFullYear();
                        const age = currentYear - birthYear;

                        // Check if age is a valid number
                        if (!isNaN(age)) {
                            loadedUsers.push({
                                username: user.username,
                                email: user.email,
                                gender: user.gender || "Not specified",
                                age: age,
                                education: user.education || "Not specified",
                                diagnoses: user.diagnoses || "Not specified"
                            });
                        }
                    }
                }
            }
        
            setNumberOfMen(menCount);
            setNumberOfWomen(womenCount);
            setUsers(loadedUsers);
      
            setZakladneVzdelanie(zakladneVzdelanieCount);
            setStredneVzdelanie(stredneVzdelanieCount);
            setVysokaSkola(vysokaSkolaCount);
        });
      
        return () => onValue(usersRef, () => {});
    }, [currentUserEmail]); 

    return (
        <div className="dashboard">
            <Sidebar className="sidebar" />
            <div className="content" >
                <h1 className="header">Grafická reprezentácia údajov</h1>
                <p>Grafy porovanávajúce počet registrovaných používateľov podľa pohlavia, veku, vzdelania a diagnóz:</p>

                <PieChart
                    series={[
                        {
                            data: [
                                { id: 0, value: numberOfMen, label: 'Muži' },
                                { id: 1, value: numberOfWomen, label: 'Ženy' },
                            ],
                            labelAccessor: (entry) => `${entry.label}: ${entry.value}`,
                        },
                    ]}
                    width={500}
                    height={200}
                />
                

                <p>
                    <strong>Legenda - Porovnanie podľa pohlavia:</strong> Počet mužov: {numberOfMen}, Počet žien: {numberOfWomen}
                </p>

                <div className="chartSeparator"></div>
                <PieChart
                    series={[
                        {
                            data: [
                                { id: 0, value: zakladneVzdelanie, label: 'Základné' },
                                { id: 1, value: stredneVzdelanie, label: 'Stredoškolské' },
                                { id: 2, value: vysokaSkola, label: 'Vysokoškolské' },
                            ],
                        },
                    ]}
                    width={500}
                    height={200}
                    legendSettings={{ position: 'right' }} 
                />


                <p>
                    <strong>Legenda - Porovnanie podľa vzdelania:</strong> Základné: {zakladneVzdelanie}, Stredoškolské: {stredneVzdelanie}, Vysokoškolské: {vysokaSkola}
                </p>
                <div className="chartSeparator"></div>
                <div className="chartContainer">
                    <ScatterChart
                        width={600}
                        height={300}
                        series={[
                            {
                                label: 'Vek ku dnešnému dátumu: ' + new Date().toLocaleDateString(),
                                data: users.filter(user => typeof user.age === 'number').map((user) => ({ x: user.age, y: user.age })),
                            },
                        ]}
                        grid={{ vertical: true, horizontal: true }}
                    />
                  <BarChart
                      width={600}
                      height={300}
                      data={users
                          .filter(user => typeof user.age === 'number')
                          .sort((a, b) => a.age - b.age) // Sort users by age
                          .map((user) => ({ vek: user.age }))
                      }
                  >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="vek" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="vek" fill="#8884d8" />
                  </BarChart>
                </div>
            </div>
        </div>
    );
}

export default Graphs;
