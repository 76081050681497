import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { Button } from '@mui/material';
import Sidebar from './sidebar';
import { getDatabase, ref, onValue } from 'firebase/database';
import * as XLSX from 'xlsx';
import xmljs from 'xml-js';
import { auth } from '../firebase';

function Report() {
  const [users, setUsers] = useState([]);
  const [numberOfMen, setNumberOfMen] = useState(0);
  const [numberOfWomen, setNumberOfWomen] = useState(0);
  const [zakladneVzdelanie, setZakladneVzdelanie] = useState(0);
  const [stredneVzdelanie, setStredneVzdelanie] = useState(0);
  const [vysokaSkola, setVysokaSkola] = useState(0);
  const [xmlUrl, setXmlUrl] = useState('');
  const [txtUrl, setTxtUrl] = useState('');
  const [currentUserEmail, setCurrentUserEmail] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
        if (user) {
            setCurrentUserEmail(user.email);
        } else {
            setCurrentUserEmail(null);
        }
    });

    return () => unsubscribe();
}, []);

  useEffect(() => {
    const usersRef = ref(db, 'users/registration/');
    onValue(usersRef, (snapshot) => {
      const data = snapshot.val();
      const loadedUsers = [];
      let menCount = 0;
      let womenCount = 0;
      let zakladneVzdelanieCount = 0;
      let stredneVzdelanieCount = 0;
      let vysokaSkolaCount = 0;

      if (data) {
        for (const userId in data) {
          const user = data[userId];
          if(user.doctor_email === currentUserEmail){
            loadedUsers.push({
              username: user.username || "Not specified",
              email: user.email || "Not specified",
              gender: user.gender || "Not specified",
              birthYear: user.birthYear || "Not specified",
              education: user.education || "Not specified",
              diagnoses: user.diagnoses || "Not specified"
            });
            if (user.gender === "Muž") {
              menCount++;
            } else {
              womenCount++;
            }
            if (user.education === "Základné") {
              zakladneVzdelanieCount++;
            } else if (user.education === "Stredoškolské") {
              stredneVzdelanieCount++;
            } else {
              vysokaSkolaCount++;
            }
          }
        }
      }

      setNumberOfMen(menCount);
      setNumberOfWomen(womenCount);
      setZakladneVzdelanie(zakladneVzdelanieCount);
      setStredneVzdelanie(stredneVzdelanieCount);
      setVysokaSkola(vysokaSkolaCount);
      setUsers(loadedUsers);
    });

    return () => onValue(usersRef, () => { });
  }, [currentUserEmail]);

  const handleGenerateXLSX = () => {
    const worksheet = XLSX.utils.json_to_sheet(users);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'report');
    XLSX.writeFile(workbook, 'report.xlsx');
  };

  const handleGenerateXML = () => {
    const xmlData = xmljs.js2xml({ users: { user: users } }, { compact: true, spaces: 4 });
    const blob = new Blob([xmlData], { type: 'application/xml' });
    const url = URL.createObjectURL(blob);
    setXmlUrl(url);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'report.xml';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleGenerateTXT = () => {
    const txtData = JSON.stringify(users, null, 2);
    const blob = new Blob([txtData], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    setTxtUrl(url);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'report.txt';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div className="dashboard">
      <Sidebar className="sidebar" />
      <div className="content">
        <h2>Generovať report z aktuálnych dát z databázy</h2>
        <Button variant="contained" onClick={handleGenerateXLSX}>
          Generovať XLSX
        </Button>
        <p> alebo </p>
        <Button variant="contained" onClick={handleGenerateXML}>
          Generovať XML
        </Button>
        <p> alebo </p>
        <Button variant="contained" onClick={handleGenerateTXT}>
          Generovať TXT
        </Button>
      </div>
    </div>
  );
}

export default Report;
